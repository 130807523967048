<template>
  <a-card :bordered="false">
    <div class="queryView">
      <div class="queryView-actions">
        <a-button type="primary" icon="plus-circle" @click="addLine()">新增</a-button>
      </div>
      <div class="queryView-main">
        <a-table
          class="queryView-table"
          rowKey="id"
          bordered
          :loading="loading"
          :columns="tableColumns"
          :dataSource="dataSource"
          :pagination="false"
          size="middle"
          :expandIconAsCell="false"
          :expandIconColumnIndex="0"
          :expandedRowRender="tableExpandedRowRender"
          @expand="tableExpand"
          :expandedRowKeys.sync="expandedRowKeys"
        ></a-table>
        <base-pagination
          :totalCount.sync="totalCount"
          :pageNo.sync="pageNo"
          :pageSize.sync="pageSize"
          @change="pageChange"
          size="small"
        />
      </div>
    </div>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" class="flex-form">
        <a-form-item label="条线">
          <a-input
            v-decorator="[
              'lineName',
              {
                rules: [{ required: true, message: '请输入名称' }],
              },
            ]"
            placeholder="请输入名称"
            :disabled="modal.type === 'ADD_TYPE' || modal.type === 'EDIT_TYPE'"
          ></a-input>
        </a-form-item>
        <template v-if="modal.type === 'ADD_LINE' || modal.type === 'EDIT_LINE'">
          <a-form-item label="备注">
            <a-input
              v-decorator="[
                'lineNote',
                {
                  rules: [{ required: true, message: '请输入备注' }],
                },
              ]"
              placeholder="请输入备注"
            ></a-input>
          </a-form-item>
        </template>
        <template v-if="modal.type === 'ADD_TYPE' || modal.type === 'EDIT_TYPE'">
          <a-form-item label="类别">
            <a-input
              v-decorator="[
                'typeName',
                {
                  rules: [{ required: true, message: '请输入类别' }],
                },
              ]"
              placeholder="请输入名称"
            ></a-input>
          </a-form-item>
          <a-form-item label="备注">
            <a-input
              v-decorator="[
                'typeNote',
                {
                  rules: [{ required: true, message: '请输入备注' }],
                },
              ]"
              placeholder="请输入备注"
            ></a-input>
          </a-form-item>
        </template>
      </a-form>
    </a-modal>
  </a-card>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
export default {
  mixins: [queryViewMixin],
  data() {
    const tableColumns = [
      {
        title: '名称',
        width: '20%',
        dataIndex: 'lineName',
        customRender: (text, row) => {
          return <span class="menu-name">{text}</span>
        },
      },
      {
        title: '备注',
        dataIndex: 'lineNote',
      },
      {
        title: '创建时间',
        width: 200,
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        width: '15%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div class="flex gap-5">
              <a
                onClick={() => {
                  this.addType(row)
                }}
              >
                新增
              </a>
              <a
                onClick={() => {
                  this.editLine(row)
                }}
              >
                修改
              </a>
              <a
                onClick={() => {
                  this.removeLine(row)
                }}
              >
                删除
              </a>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      modal: {
        visible: false,
        data: {},
        form: this.$form.createForm(this),
        title: '',
      },
      expandedRowKeys: [],
    }
  },
  computed: {
    typesMap() {
      return {}
    },
  },

  methods: {
    async tableExpand(expanded, record) {},
    tableExpandedRowRender(record, tableIndex) {
      const list = record.opsTypes || []
      return (
        <div class="expandedRow-table">
          <div class="expandedRow-table-body">
            {list.map((row) => {
              return (
                <div class="expandedRow-table-row">
                  <div class="expandedRow-table-col" style="padding-left:60px">
                    {row.typeName}
                  </div>
                  <div class="expandedRow-table-col">{row.typeNote}</div>
                  <div class="expandedRow-table-col">{row.createTime}</div>
                  <div class="expandedRow-table-col" style="align-self:center">
                    <div class="flex gap-5 justify-center text-primary-500">
                      <a class="pointer-events-none">&emsp;&emsp;</a>
                      <a
                        onClick={() => {
                          this.editType(record, row)
                        }}
                      >
                        修改
                      </a>
                      <a
                        onClick={() => {
                          this.removeType(row)
                        }}
                      >
                        删除
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    },

    addLine() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增条线'
      this.modal.type = 'ADD_LINE'
    },
    editLine(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '编辑条线'
      this.modal.type = 'EDIT_LINE'
      this.modal.data = {
        lineId: row.id,
        lineCode: row.lineCode,
      }
      this.safeSetFieldsValue(this.modal.form, {
        lineName: row.lineName,
        lineNote: row.lineNote,
      })
    },
    async removeLine(row) {
      if (row.opsTypes.length) {
        return this.$error({
          title: '请先删除记录下的所有子项',
        })
      }
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认删除记录?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/ops-server/opsLine/del', {
        id: row.id,
        lineCode: row.lineCode,
      })
      this.$message.success('操作成功')
      this.doQuery()
    },
    addType(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增类型'
      this.modal.type = 'ADD_TYPE'
      this.modal.data = {
        lineCode: row.lineCode,
      }
      this.safeSetFieldsValue(this.modal.form, {
        lineName: row.lineName,
      })
    },
    editType(line, type) {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '编辑类型'
      this.modal.type = 'EDIT_TYPE'
      this.modal.data = {
        lineCode: line.lineCode,
        typeCode: type.typeCode,
        typeId: type.id,
      }
      this.safeSetFieldsValue(this.modal.form, {
        lineName: line.lineName,
        typeName: type.typeName,
        typeNote: type.typeNote,
      })
    },
    async removeType(type) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认删除记录?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/ops-server/opsType/del', {
        id: type.id,
        typeCode: type.typeCode,
      })
      this.$message.success('操作成功')
      this.doQuery()
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          const { lineCode, typeCode, lineId, typeId } = this.modal.data
          try {
            if (this.modal.type === 'ADD_LINE') {
              await this.$axios.post('/ops-server/opsLine/add', {
                ...params,
              })
            }
            if (this.modal.type === 'EDIT_LINE') {
              await this.$axios.post('/ops-server/opsLine/put', {
                id: lineId,
                lineCode,
                ...params,
              })
            }
            if (this.modal.type === 'ADD_TYPE') {
              await this.$axios.post('/ops-server/opsType/add', {
                lineCode,
                ...params,
              })
            }
            if (this.modal.type === 'EDIT_TYPE') {
              await this.$axios.post('/ops-server/opsType/put', {
                lineCode,
                id: typeId,
                typeCode,
                ...params,
              })
            }

            this.$message.success('操作成功!')
            this.doQuery()
          } catch (e) {
            console.error(e)
          }

          this.modal.visible = false
        }
      })
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.getPageParams(_pageNo, _pageSize)
        const res = await this.$axios.post('/ops-server/opsLine/lineTypeList', {
          ...params,
        })
        const list = res.data.data
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
  async mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped>
.expandedRow-table {
  // margin: -16px;
  margin: -12px -8px;
  background: #f4f4f4;
  .expandedRow-table-row {
    display: grid;
    grid-template-columns: 20% 1fr 200px 15%;
  }
  .expandedRow-table-head {
    position: relative;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background-color: transparent;
    }
    font-weight: bold;
  }
  .expandedRow-table-body .expandedRow-table-row:last-child {
    .expandedRow-table-col {
      border-bottom: 0;
    }
  }
  .expandedRow-table-col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 5px;
    border-bottom: 1px solid #dfdfdf;
  }
  .expandedRow-table-body {
    .expandedRow-table-col {
      padding: 12px 8px;
    }
  }
  .expandedRow-table-head {
    font-weight: bold;
  }
}
</style>
